import React, { Suspense } from "react";
import { Route, Routes, BrowserRouter as Routers } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import PageLoding from "../components/PageLoding";

const Home = React.lazy(() => import("../view/index"));

export default function Router() {
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="/*" element={<MainLayout />}>
          {/* 首页 */}
          <Route index element={<Home />} />
          <Route path="" element={<Home />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
