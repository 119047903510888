import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ViewportProvider from "./components/viewportContext";
import store from "./store";
import "antd/dist/antd.min.css";

import "./lang/i18n";
import "./index.css"; //公共样式
import "./App.scss"; //pc样式
import "./assets/css/h5.scss"; // h5样式
ReactDOM.render(
  <React.StrictMode>
    <ViewportProvider>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ViewportProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// const root = ReactDOM?.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </Provider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
