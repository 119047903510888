const windowHost = window.location.origin;

export const LOCAL_KEY = "MBAS_LANG";

let pattern =
  /^(http:\/\/localhost|http:\/\/192\.168|https:\/\/localhost|https:\/\/192\.168|file:\/\/*)/i;
// 判断环境
export const isMain = true; //上线需打开
// export const isMain = pattern.test(windowHost) ? false : true; //上线需打开
// export const isMain = pattern.test(windowHost) ? true : true; //本地测试
// 请求api
export let baseUrl: string = isMain
  ? "https://app.happyape.me/test"
  : // :"https://www.losgameceshi.xyz";
    "https://app.happyape.me/test";

// 合约相关配置
export const LOCAL_RPC = isMain
  ? "https://mainnet.helius-rpc.com/?api-key=34778cf4-2c5b-4584-ad71-a157b7e14eb5"
  : "https://api.devnet.solana.com/"; // rpc
export const LOCAL_programId = isMain
  ? "YGk7dtc8tUnJZrahgCdiJs6wczFxM6Uu1uQpwXHFKWA"
  : "8a5Xvjsu1banMDQRBmasSrCefJwLRnuKqkeYY5Cotxhx"; // programId
export const usdt_mint = isMain
  ? "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
  : "7hXHw4jkkGRaZnybB8LuxqEQ7Gicg1QYJ5DhmYw4oHqj"; // usdt
export const usdt_receiver = isMain
  ? "ABcJkE8E1pLGEccamvc2DKQ79zHqDBjyKpbXFSsF459q"
  : "2B5zzXyimNCBfR4oqG84yHGxyYb3bc6CENGtQzjnAT3S"; // to address
export const programId = "8D6pyFMvPqJgDchysjnPqAZ7qVAQUvph5V9F3b7HyUNv";
export const tokenProgram = "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
